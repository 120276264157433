import React from "react";

const AptDisclaimer = ({ apartment_group }) => {
  return (
    <div>
      {/* {apartment_group === "JSM" && (
        <div className="mx-auto p-4 md:px-16">
          <div
            className="bg-[#EDF7ED] border-l-4 border-[#4CAF50] text-[#2E7D32] p-4 rounded-md shadow-lg"
            role="alert"
          >
            <p className="font-bold text-md">
              JSM Leasing Appointments Start October 2!
            </p>
            <p className="mt-1">
              View more info and schedule your appointment{" "}
              <a
                href="https://jsmliving.com/virtual-lease-signing-appointments"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#2E7D32] underline decoration-[#4CAF50]"
              >
                here
              </a>
            </p>
            {/* <p className="mt-1">
              Tours Available! Call JSM at{" "}
              <a
                href="tel:217-610-8203"
                className="text-[#2E7D32] underline decoration-[#4CAF50]"
              >
                217-610-8203
              </a>{" "}
              to schedule yours today.
            </p>}
          </div>
        </div>
      )} */}
      {apartment_group === "UGroup" && (
        <div className="mx-auto p-4 md:px-16">
          <div
            className="bg-[#E3E9F2] border-l-4 border-[#0A2463] text-[#0A2463] p-4 rounded-md"
            role="alert"
          >
            <p className="font-bold">UPDATED 2025 RATES JUST RELEASED</p>
            <p>
              Leasing for August 2025 begins in October. Feel free to browse and
              request a virtual tour!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AptDisclaimer;
